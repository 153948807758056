
import './App.css'
import React, {useState, useRef} from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { useAuthState } from 'react-firebase-hooks/auth'
import {useCollectionData} from 'react-firebase-hooks/firestore'

firebase.initializeApp({
  apiKey: "AIzaSyCi9sUdDE8TaNy6kJnwevnYz5O1ngwbVfY",
  authDomain: "birthday-64073.firebaseapp.com",
  projectId: "birthday-64073",
  storageBucket: "birthday-64073.appspot.com",
  messagingSenderId: "637086101066",
  appId: "1:637086101066:web:5e0575ab13971ea30740b3"
})

const auth = firebase.auth();
const firestore = firebase.firestore();
function App() {
  const[user] = useAuthState(auth)
  return (
    <div className="App">
    <header>
      <h1>HINZWIFI</h1>
      <SignOut />
    </header>

    <section>
      {user ? <ChatRoom /> : <SignIn />}
    </section>

  </div>
  );
}

function SignIn(){
  const SignInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    auth.signInWithPopup(provider);
    
  }
  const SignInWithGithub = () => {
    const provider = new firebase.auth.GithubAuthProvider()
    auth.signInWithPopup(provider);
    
  }
  return(
    <>
    <button onClick={SignInWithGoogle}>Sign In with Google</button>
    <button onClick={SignInWithGithub}>Sign In with Github</button>
    </>
  )
}

function SignOut(){
  return auth.currentUser && (
    <button onClick={()=> auth.signOut()}>Sign Out</button>
  )
}
function ChatRoom(){
  const messagesRef = firestore.collection('messages')
  const query = messagesRef.orderBy('createdAt').limit(25)
  const [messages] = useCollectionData(query, {idField:'id'})
  const [formValue , setFormValue] = useState('')
  const dummy = useRef()
  const sendMessage = async(e) => {
    
    e.preventDefault()
    const {uid, photoURL} = auth.currentUser
    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL
      
    })
    setFormValue('')
    dummy.current.scrollIntoView({behavior: 'smooth'})
  }
  return(
    <>
    <main>
      {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} /> )}
      <div ref={dummy}></div>
    </main>
    <form onSubmit={sendMessage}>
      <input value={formValue} onChange={(e)=> setFormValue(e.target.value)} />

      <button type="submit" disabled={!formValue}> Send</button>

    </form>
    </>
  )
}

function ChatMessage(props){
  const {text, uid, photoURL} = props.message
  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received'

  return (
    <div className={`message ${messageClass}`}>
      <img src={photoURL} />
      <p>{text}</p>
    </div>
    )
}


export default App;
